import React, { useState, useEffect } from "react";
import axios from "axios";
import { useRouter } from "next/router";
import Link from "next/link";
import { faComment, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";

import CustomHead from "../../components/common/head/Head";
import BlogCommentArea from "../../components/common/blog/BlogCommentArea";
import LatestBlog from "../../components/common/blog/LatestBlog";
import { apiUrl, baseUrl } from "../../config/apiConfig";
import { stringLimit, getTextContent } from "../../helper/string";
import { formatedDate } from "../../helper/time";
import { fbID } from "../../config/socialConfig";
import LatestCategories from "../../components/common/blog/LatestCategories";
import Custom404 from "../_error";
import PageOutline from "../../components/common/blog/PageOutline";
import * as htmlparser2 from "htmlparser2";
import CustomImage from "../../components/common/image/CustomImage";
import RealatedBlogArea from "../../components/common/blog/RelatedBlogArea";
import image1 from "../../public/images/testimonial/3.png";

import CommentBox from "../../components/common/comment/CommentBox";
import BlogJsonLD from "../../components/pages/blog/BlogJsonLD";
import HtmlTransformer from "../../components/pages/blog/HtmlTransformer";
import { toast } from "react-toastify";
import BlogDetailsComponent from "../../components/pages/blog/BlogDetailsComponent";
const BlogDetail = ({ blogDetails, siteSetting }) => {
//   console.log("blogDetails", blogDetails);
  // console.log('blogDetails =>', blogDetails && blogDetails);
  const router = useRouter();
  const alert = toast;

  const [blogInfo, setBlogInfo] = useState(null);
  const [latestBlogs, setlatestBlogs] = useState([]);
  const [blogCategories, setblogCategories] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [blogComments, setblogComments] = useState([]);
  const [blogCommentsCount, setblogCommentsCount] = useState([]);
  const [outlines, setOutlines] = useState([]);
  const [showScroll, setShowScroll] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const scrollDown = () => {
    if (window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (window.pageYOffset <= 50) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollDown);
    return () => window.removeEventListener("scroll", scrollDown);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (blogDetails) {
        // console.log(blogDetails.blogData);
        setBlogInfo(blogDetails.blogData);
        setblogCategories(blogDetails.blog_categories);
        setRelatedBlogs(blogDetails.relatedBlogs);
        setlatestBlogs(blogDetails.latestBlogs);
        setblogComments(blogDetails.blog_comments);
        setblogCommentsCount(blogDetails.blog_commentcount);
        if (blogDetails.blogData && blogDetails.blogData.long_description) {
          let outlineArray = [];
          const dom = htmlparser2.parseDocument(
            blogDetails.blogData.long_description
          );
          // console.log("dom", dom);
          if (dom && dom.children && dom.children.length > 0) {
            for (let index = 0; index < dom.children.length; index++) {
              const domChild = dom.children[index];
              if (
                domChild &&
                domChild.attribs &&
                domChild.attribs.class &&
                domChild.attribs.class == "content_heading" &&
                domChild.attribs.id &&
                domChild.name &&
                domChild.name == "h1"
              ) {
                let id = domChild.attribs.id;
                let text =
                  domChild.children && domChild.children.length > 0
                    ? domChild.children[0].data
                    : id;
                outlineArray.push({
                  id: id,
                  text: text,
                });
              }
            }
          }
          // console.log("outlineArray", outlineArray);
          setOutlines(outlineArray);
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [router, blogDetails]);

  if (!blogDetails) {
    return <Custom404 />;
  }

  const handleNewsletterSubmit = (formData, e) => {
    let bodyFormData = new FormData();
    bodyFormData.append("email", formData.email ? formData.email : "");

    axios({
      method: "post",
      url: apiUrl + "/api/newsletterSuscribe",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        alert.info("newsletter subscribe");
        e.target.reset();
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      <CustomHead
        title={
          (blogDetails.blogData && blogDetails.blogData.blog_name
            ? blogDetails.blogData.blog_name
            : "Blog Details") +
          " | " +
          (siteSetting && siteSetting.title ? siteSetting.title : metaSiteTitle)
        }
        metaTitle={
          blogDetails.blogData && blogDetails.blogData.blog_name
            ? blogDetails.blogData.blog_name
            : "Blog Details"
        }
        description={
          blogDetails.blogData && blogDetails.blogData.long_description
            ? stringLimit(getTextContent(blogDetails.blogData.long_description))
            : ""
        }
        url={
          blogDetails.blogData && blogDetails.blogData.slug
            ? baseUrl + "/" + blogDetails.blogData.slug
            : ""
        }
        image={
          blogDetails.blogData && blogDetails.blogData.image
            ? apiUrl + blogDetails.blogData.image
            : ""
        }
        type={"article"}
      >
        <BlogJsonLD blogDetails={blogDetails} />
      </CustomHead>
	  <section>
      <BlogDetailsComponent
        blogData={blogInfo}
        latestBlogs={latestBlogs}
        relatedBlogs={relatedBlogs}
      />
      
        {/* Related blog area start*/}
        <div className="my-5">
          <RealatedBlogArea relatedBlogs={relatedBlogs} />
        </div>
        {/* Related blog area end */}
      </section>
    </>
  );
};

export async function getServerSideProps(params) {
  try {
    const blogDetails = await axios
      .get(apiUrl + "/api/blogDetails?slug=" + params.query.slug)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return null;
      });

    // console.log(blogDetails);

    const siteSetting = await axios
      .get(apiUrl + "/api/siteSetting")
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log("error", error);
        return null;
      });

    return {
      props: {
        blogDetails: blogDetails,
        siteSetting: siteSetting,
        loaded: true,
      },
    };
  } catch (error) {
    return {
      props: {
        blogDetails: null,
        siteSetting: null,
        loaded: true,
      },
    };
  }
}

export default BlogDetail;
